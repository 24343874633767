<template>
    <div>
        <div class="listToggle awwardsLead">
            <!-- Nested Tabs -->
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'today' }" @click="activateTab('today')" data-toggle="tab" href="#today" role="tab">Today</div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'yesterday' }" @click="activateTab('yesterday')" data-toggle="tab" href="#yesterday" role="tab">Yesterday</div>
                </li>
            </ul>
            <div class="awwardsIconBox awwwards-icon">
                <img :src='TGAwwardsIcon' @click="showRewardPopup" alt="Awwards Icon" />
            </div>
        </div>
        <div class="tab-content">
            <div class="tab-pane" :class="{ 'active': activeTab === 'today' }" id="today" role="tabpanel">
                <div class="rewards-bg">
                    <h3 class="txtb">Today</h3>
                    <img class="date-bg" :src='TopGameTodayDateBG' alt="Date Bg" />
                    <img class="list-top-bg" :src='TopGameTodayListTopBG' alt="List Top Bg" />
                    <div v-if="isLoadedToday">
                        <TopGameFlipCoinTodayTabCont />
                    </div>                    
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'yesterday' }" id="yesterday" role="tabpanel">
                <div class="rewards-bg">
                    <h3 class="txtb">Yesterday</h3>
                    <img class="date-bg" :src='TopGameTodayDateBG' alt="Date Bg" />
                    <img class="list-top-bg" :src='TopGameTodayListTopBG' alt="List Top Bg" />
                    <div v-if="isLoadedYesterday">
                        <TopGameFlipCoinYesterdayTabCont />
                    </div>
                </div>
            </div>
        </div>

        <!-- Reward Popup -->
        <div class="rewardPopupWrapper" v-show="isRewardPopupVisible">
            <div class="rewardPopupInner scale-up-bottom">                
                <img :src='popupClosed' class="closed" @click="hideRewardPopup">
                <div class="rewards-bg">
                    <h4 class="h4">Total Rewards</h4>
                    <img class="date-bg" :src='TopGameTodayDateBG' alt="Date Bg" />
                    <img class="list-top-bg" :src='TopGameTodayListTopBG' alt="List Top Bg" />
                    <div class="rewardPopupContBox">
                        <div class="reward-boxWrap">
                            <div class="mainReward">
                                <div class="reward-box">
                                <div class="reward-HDBox">
                                    <h3>Daily Reward</h3>
                                    <span>
                                        <span v-if="flipCoinGameDailyTotalReward !== null">{{ flipCoinGameDailyTotalReward}}</span>
                                        <img :src='diamondIcon' class="diamondIcon" alt="diamond icon" /> 
                                    </span>
                                </div>
                                <div class="diamondIcon-box">
                                    <img :src='multiDiamondImage' alt="Diamond Icons Imgae" />
                                </div>
                                </div>
                            </div>
                            <div class="coin-box">
                                <div class="coin-text">
                                <img :src='rewardCoin1' class="coin-Icon" alt="R1" />
                                <span>
                                    <span v-if="flipCoinGameDailyRewards.length > 0">{{ flipCoinGameDailyRewards[0].reward_coin}}</span>
                                    <img :src='diamondIcon' class="diamondIcon" alt="diamond icon" /> 
                                </span>
                                </div>
                                <div class="coin-text">
                                <img :src='rewardCoin2' class="coin-Icon" alt="R2" />
                                <span>
                                    <span v-if="flipCoinGameDailyRewards.length > 1">{{ flipCoinGameDailyRewards[1].reward_coin}}</span>
                                    <img :src='diamondIcon' class="diamondIcon" alt="diamond icon" /> 
                                </span>
                                </div>
                                <div class="coin-text">
                                <img :src='rewardCoin3' class="coin-Icon" alt="R3" />
                                <span>
                                    <span v-if="flipCoinGameDailyRewards.length > 2">{{ flipCoinGameDailyRewards[2].reward_coin}}</span>
                                    <img :src='diamondIcon' class="diamondIcon" alt="diamond icon" /> 
                                </span>
                                </div>
                            </div>
                            <div class="rank-box"> 
                                <span>Rank 4th to 10th &nbsp;:&nbsp; 
                                    <span v-if="flipCoinGameDailyRewards.length > 3">{{ flipCoinGameDailyRewards[3].reward_coin}}</span>
                                <img :src='diamondIcon' class="diamondIcon" alt="diamond icon" /> 
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <!-- Reward Popup End -->
    </div>
</template>
<script>
import axios from 'axios';
import TopGameFlipCoinTodayTabCont from './TopGameFlipCoinTodayTabCont.vue'
import TopGameFlipCoinYesterdayTabCont from './TopGameFlipCoinYesterdayTabCont.vue'
import TGAwwardsIcon from '../../assets/images/TG-awwards-icon.png';
import TopGameTodayDateBG from '../../assets/images/TopGameToday-date-bg.png'
import TopGameTodayListTopBG from '../../assets/images/TopGameToday-list-top-bg.png'
import diamondIcon from '../../assets/images/diamond.png';
import multiDiamondImage from '../../assets/images/diamonds-image.png';
import popupClosed from '../../assets/images/popupClosed.svg';
import rewardCoin1 from '../../assets/images/r1.png'
import rewardCoin2 from '../../assets/images/r2.png'
import rewardCoin3 from '../../assets/images/r3.png'
export default {
    name: 'TopGameFlipCoinTabs',
    components: {
        TopGameFlipCoinTodayTabCont,
        TopGameFlipCoinYesterdayTabCont
    },
    data() {
        return {
            activeTab: 'today',
            isRewardPopupVisible: false,
            flipCoinGameDailyRewards: [],
            flipCoinGameDailyTotalReward: null,
            TGAwwardsIcon: TGAwwardsIcon,
            TopGameTodayDateBG: TopGameTodayDateBG,
            TopGameTodayListTopBG: TopGameTodayListTopBG,
            diamondIcon: diamondIcon,
            multiDiamondImage: multiDiamondImage,
            popupClosed: popupClosed,
            rewardCoin1: rewardCoin1,
            rewardCoin2: rewardCoin2,
            rewardCoin3: rewardCoin3,
            isLoadedToday: false,
            isLoadedYesterday: false
        };
    },
    mounted () {
        this.fetchTotalRewardCoins();
        this.isLoadedToday = true;
    },
    methods: {    
        fetchTotalRewardCoins(){
            axios.get('https://zeep.live/api/top-flipcoin-game').then(response =>{
                this.flipCoinGameDailyRewards = response.data.result.dailyrewards;
                this.flipCoinGameDailyTotalReward = response.data.result.dailytotalreward;
            })
            .catch(error => {
                console.error('Error fetching rewards:', error);
            })
        },    
        showRewardPopup() {
            this.isRewardPopupVisible = true;
        },
        hideRewardPopup() {
            this.isRewardPopupVisible = false;
        },
        activateTab(tabId) {
            this.activeTab = tabId;
            if (tabId === 'today' && !this.isLoadedToday) {
                this.isLoadedToday = true;
            }
            if (tabId === 'yesterday' && !this.isLoadedYesterday) {
                this.isLoadedYesterday = true;
            }
        },
    }
}
</script>
