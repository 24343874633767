import ParentabsComponent from './components/ParentTabsComponent/ParentTabsComponent.vue'
import HistoryTabComponent from './components/LuckyNumber/HistoryTabComponent.vue'
import HistoryDetails from './components/LuckyNumber/HistoryDetails.vue';
import Rules from './components/LuckyNumber/Rules.vue'
import LuckyRewards from './components/LuckyNumber/LuckyRewards.vue'
import LuckyStatistics from './components/LuckyNumber/LuckyStatistics.vue'
import CarGameHistoryTabComponent from './components/CarGame/HistoryTabComponent.vue'
import CarGameHistoryDetails from './components/CarGame/CarGameHistoryDetails.vue';
import CarGameRankTabComponent from './components/CarGame/CarGameRankTabComponent.vue';
import CarGameWinRateRank from './components/CarGame/CarGameWinRateRank.vue';
import CarGameSpeedRank from './components/CarGame/CarGameSpeedRank.vue';
import CarGameRoadGraph from './components/CarGame/CarGameRoadGraph.vue';
import CarGameRules from './components/CarGame/CarGameRules.vue';

import FlipCoinHistoryTabComponent from './components/FlipCoin/HistoryTabComponent.vue'
import FlipCoinHistoryDetails from './components/FlipCoin/FlipCoinHistoryDetails.vue';
import FlipCoinRules from './components/FlipCoin/FlipCoinRules.vue';

import IconicHost from './components/IconicHost/IconicHost.vue'
import {createRouter, createWebHistory} from 'vue-router'

const routes =[
    {
        name: 'ParentabsComponent',
        component: ParentabsComponent,
        path: '/'
    },

    // Lucky Number Game URl
    {
        name: 'HistoryTabComponent',
        component: HistoryTabComponent,
        path: '/luckynumber/history-tab/user_id=:user_id'
    },
    {
        name: 'HistoryDetails',
        component: HistoryDetails,
        path: '/luckynumber/history-details/contest_id=:contest_id/user_id=:user_id',
    },
    {
        name: 'LuckyStatistics',
        component: LuckyStatistics,
        path: '/luckynumber/statistics/user_id=:user_id',
    },
    {
        name: 'Rules',
        component: Rules,
        path: '/luckynumber/rules',
    },
    {
        name: 'LuckyRewards',
        component: LuckyRewards,
        path: '/luckynumber/lucky-rewards/profile_id=:profile_id',
    },

    // Car Game URl
    {
        name: 'CarGameHistoryTabComponent',
        component: CarGameHistoryTabComponent,
        path: '/cargame/history-tab/user_id=:user_id'
    },
    {
        name: 'CarGameHistoryDetails',
        component: CarGameHistoryDetails,
        path: '/cargame/history-details/contest_id=:contest_id/user_id=:user_id',
    },
    {
        name: 'CarGameRankTabComponent',
        component: CarGameRankTabComponent,
        path: '/cargame/rank',
    },
    {
        name: 'CarGameWinRateRank',
        component: CarGameWinRateRank,
        path: '/cargame/winrate_rank',
    },
    {
        name: 'CarGameSpeedRank',
        component: CarGameSpeedRank,
        path: '/cargame/speed_rank/contest_id=:contest_id',
    },
    {
        name: 'CarGameRoadGraph',
        component: CarGameRoadGraph,
        path: '/cargame/car_road_graph',
    },
    {
        name: 'CarGameRules',
        component: CarGameRules,
        path: '/cargame/rules',
    },

    // FlipCoin URl
    {
        name: 'FlipCoinHistoryTabComponent',
        component: FlipCoinHistoryTabComponent,
        path: '/flipcoin/history-tab/user_id=:user_id'
    },
    {
        name: 'FlipCoinHistoryDetails',
        component: FlipCoinHistoryDetails,
        path: '/flipcoin/history-details/contest_id=:contest_id/user_id=:user_id',
    },
    {
        name: 'FlipCoinRules',
        component: FlipCoinRules,
        path: '/flipcoin/rules',
    },

    // IconicHost
    {
        name: 'IconicHost',
        component: IconicHost,
        path: '/iconichost/iconic-host'
    },
]
const router = createRouter({
    history:createWebHistory(),
    routes
})
export default router