<template>
    <div class="mainTabs-wrapper">
        <div id="parentTabIn" class="parentTabIn">
            <div class="backArrowIcon" id="close" @click="viewClose('someStatus')"><img :src='downArrow' alt="Back Arrow" /></div>
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" style="display: none;">
                    <div class="nav-link" :class="{ 'active': activeTab === 'topPkTab' }"
                        @click="activateTab('topPkTab')" data-toggle="tab" href="#topPkTab" role="tab">Top PK
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'topCharmTab' }"
                        @click="activateTab('topCharmTab')" data-toggle="tab" href="#topCharmTab" role="tab">Top Charm
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'topRichTab' }"
                        @click="activateTab('topRichTab')" data-toggle="tab" href="#topRichTab" role="tab">Top Rich
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'topCallTab' }"
                        @click="activateTab('topCallTab')" data-toggle="tab" href="#topCallTab" role="tab">Top Call
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'topGameTab' }"
                        @click="activateTab('topGameTab')" data-toggle="tab" href="#topGameTab" role="tab">Top Games
                    </div>
                </li>
            </ul>
        </div>
        <div class="tab-content">
            <div class="tab-pane" :class="{ 'active': activeTab === 'topPkTab' }" id="topPkTab" role="tabpanel">
                <div class="tab-contentIn topPk">
                    <div class="mainWrapper">
                        <div class="component-box" v-if="isLoadedTopPk">
                            <TopPk />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'topCharmTab' }" id="topCharmTab" role="tabpanel">
                <div class="tab-contentIn topCharm">
                    <div class="mainWrapper">
                        <div class="component-box" v-if="isLoadedTopCharm">
                            <TopCharm />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'topRichTab' }" id="topRichTab" role="tabpanel">
                <div class="tab-contentIn topRich">
                    <div class="mainWrapper">
                        <div class="component-box" v-if="isLoadedTopRich">
                            <TopRich />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'topCallTab' }" id="topCallTab" role="tabpanel">
                <div class="tab-contentIn topCall">
                    <div class="mainWrapper">
                        <div class="component-box" v-if="isLoadedTopCall">
                            <TopCall />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'topGameTab' }" id="topGameTab" role="tabpanel">
                <div class="tab-contentIn topGames">
                    <div class="mainWrapper">
                        <div class="component-box" v-if="isLoadedTopGames">
                            <TopGames />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import downArrow from '../../assets/images/downArrow.png';
import TopPk from '../../components/TopPk/TopPk.vue'
import TopCharm from '../../components/TopCharm/TopCharm.vue'
import TopRich from '../../components/TopRich/TopRich.vue'
import TopCall from '../../components/TopCall/TopCall.vue'
import TopAgency from '../../components/TopAgency/TopAgency.vue'
import TopGames from '../../components/TopGames/TopGames.vue'
export default {
    name: 'ParentTabComponent',
    components: {
        TopPk,
        TopCharm,
        TopRich,
        TopCall,
        TopAgency,
        TopGames
    },
    data() {
        return {
            activeTab: 'topCharmTab',
            isParentTabVisible: true,
            downArrow: downArrow,
            isLoadedTopPk: false,
            isLoadedTopCharm: false,
            isLoadedTopRich: false,
            isLoadedTopCall: false,
            isLoadedTopGames: false,
        };
    },
    methods: {
        activateTab(tabId) {
            this.activeTab = tabId;
            if (tabId === 'topPkTab' && !this.isLoadedTopPk) {
                this.isLoadedTopPk = true;
            }
            if (tabId === 'topCharmTab' && !this.isLoadedTopCharm) {
                this.isLoadedTopCharm = true;
            }
            if (tabId === 'topRichTab' && !this.isLoadedTopRich) {
                this.isLoadedTopRich = true;
            }
            if (tabId === 'topCallTab' && !this.isLoadedTopCall) {
                this.isLoadedTopCall = true;
            }
            if (tabId === 'topGameTab' && !this.isLoadedTopGames) {
                this.isLoadedTopGames = true;
            }
        },
        //Close Webview on back arrow click
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);
            }
        }
    },
    mounted() {
        this.isLoadedTopCharm = true;
    },
}
</script>