<template>
    <div>
        <LoadingOverlay :loading="loading" />
        <div class="listToggle awwardsLead">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" @click="showTimesBox">
                    <div class="nav-link" :class="{ 'active': activeTab === 'thisweek' }"
                        @click="activateTab('thisweek')" data-toggle="tab" href="#thisweek" role="tab">This Week</div>
                </li>
                <li class="nav-item" @click="hideTimesBox">
                    <div class="nav-link" :class="{ 'active': activeTab === 'lastweek' }"
                        @click="activateTab('lastweek')" data-toggle="tab" href="#lastweek" role="tab">Last Week</div>
                </li>
            </ul>
            <div class="timesBox" v-show="isTimesBoxVisible">
                <CounterdownCompo :isVisible="true" :showDays="true" />
            </div>
        </div>
        <div>
            <div class="rewards-bg">
                <img class="info-icon" :src='pkInfoIcon' @click="showRulesPopup" alt="Information Icon" />
                <img class="awwwards fixedInside" :src='pkAwwards' @click="showRewardPopup" alt="Awards Icon" />
                <img class="listTopBgLine" :src='pkTopBorderLineBg' alt="List Top Bg">
                <div class="tab-content">
                    <div class="tab-pane" :class="{ 'active': activeTab === 'thisweek' }" id="thisweek" role="tabpanel">
                        <div v-if="isLoadedThisWeek">
                            <TopPkThisWeekTabCont />
                        </div>                        
                    </div>
                    <div class="tab-pane" :class="{ 'active': activeTab === 'lastweek' }" id="lastweek" role="tabpanel">
                        <div v-if="isLoadedLastWeek">
                            <TopPkLastWeekTabCont />
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
        <!-- Reward Popup Start -->
        <div class="mode-area" v-show="isPopupOverlayVisible"></div>
        <div
            :class="{ 'rewardPopupWrapper': true, 'transform-in': rewardsPopupVisible, 'transform-out': !rewardsPopupVisible }">
            <div class="rewardPopupInner scale-up-bottom">
                <h4 class="h4">CURRENT REWARD</h4>
                <img :src='popupClosed' class="closed" @click="showRewardPopup">
                <div class="rewardPopupContBox">
                    <div class="coin-box">
                        <div class="coin-text">
                            <div class="img-img">
                                <div class="overlap">
                                    <img :src='crown2' alt="" />
                                </div>
                            </div>
                            <span class="spanBox">
                                <img :src='beansIcon' class="beansIcon" alt="Beans icon" />
                                <span v-if="weeklyCurrentRewardData.length > 1">{{
            weeklyCurrentRewardData[1].reward_coin }}</span>
                            </span>
                        </div>
                        <div class="coin-text active">
                            <div class="img-img">
                                <div class="overlap">
                                    <img :src='crown1' alt="" />
                                </div>
                            </div>
                            <span class="spanBox">
                                <img :src='beansIcon' class="beansIcon" alt="Beans icon" />
                                <span v-if="weeklyCurrentRewardData.length > 0">{{
            weeklyCurrentRewardData[0].reward_coin }}</span>
                            </span>
                        </div>
                        <div class="coin-text">
                            <div class="img-img">
                                <div class="overlap">
                                    <img :src='crown3' alt="" />
                                </div>
                            </div>
                            <span class="spanBox">
                                <img :src='beansIcon' class="beansIcon" alt="Beans icon" />
                                <span v-if="weeklyCurrentRewardData.length > 2">{{
            weeklyCurrentRewardData[2].reward_coin
        }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="podium">
                        <img class="podiumImg" :src='pkPodiumImage' alt="" />
                    </div>
                    <div class="rank-box">
                        <span>4th to 10th</span>
                        <div class="righticon">
                            <span class="spanBox">
                                <img :src='beansIcon' class="beansIcon" alt="Beans icon" />
                                <span v-if="weeklyCurrentRewardData.length > 3">{{
            weeklyCurrentRewardData[3].reward_coin }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="rank-box">
                        <span>11th to 20th</span>
                        <div class="righticon">
                            <span class="spanBox">
                                <img :src='beansIcon' class="beansIcon" alt="Beans icon" />
                                <span v-if="weeklyCurrentRewardData.length > 3">{{
            weeklyCurrentRewardData[3].reward_coin }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Info Popup Start  -->
        <div id="rulesPopup"
            :class="{ 'popup_box': true, 'rule-pop-up': true, 'transform-in': rulesPopupVisible, 'transform-out': !rulesPopupVisible }">
            <div class="closePopup rulePopupCloseBtn">
                <img :src='popupGrayCloseIcon' @click="showRulesPopup" alt="" />
            </div>
            <div class="rulePopup-topHD">
                <div class="rp-HD">RULES</div>
            </div>
            <div class="rule-content">
                <ul class="rule-listWrap">
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import TopPkThisWeekTabCont from './TopPkThisWeekTabCont.vue';
import TopPkLastWeekTabCont from './TopPkLastWeekTabCont.vue';
import LoadingOverlay from '../LoadingOverlay.vue'
import CounterdownCompo from '../../components/CounterdownCompo.vue';
import beansIcon from '../../assets/images/beans-icon.png'
import pkInfoIcon from '../../assets/images/pk-infoIcon.svg'
import pkAwwards from '../../assets/images/pk-awwards.png'
import pkTopBorderLineBg from '../../assets/images/pk-top-border-line-bg.png'
import crown1 from '../../assets/images/crown_1.png';
import crown2 from '../../assets/images/crown_2.png';
import crown3 from '../../assets/images/crown_3.png';
import pkPodiumImage from '../../assets/images/pk-podium-image.svg'
import popupClosed from '../../assets/images/popupClosed.svg';
import popupGrayCloseIcon from '../../assets/images/popupCloseIcon-gray.svg';
export default {
    name: 'TopPk',
    components: {
        TopPkThisWeekTabCont,
        TopPkLastWeekTabCont,
        CounterdownCompo,
        LoadingOverlay
    },
    data() {
        return {
            isTimesBoxVisible: true,
            // isRewardPopupVisible: false,
            activeTab: 'thisweek',
            isLoadedThisWeek: false,
            isLoadedLastWeek: false,
            weeklyCurrentRewardData: [],
            beansIcon: beansIcon,
            pkInfoIcon,
            pkAwwards,
            pkTopBorderLineBg,
            crown1: crown1,
            crown2: crown2,
            crown3: crown3,
            pkPodiumImage: pkPodiumImage,
            popupClosed: popupClosed,
            popupGrayCloseIcon: popupGrayCloseIcon,
            rewardsPopupVisible: false,
            rulesPopupVisible: false,
            loading: false,
        };
    },
    mounted() {
        this.fetchWeeklyCurrentRewardData();
        this.isLoadedThisWeek = true;
    },
    methods: {
        showTimesBox() {
            this.isTimesBoxVisible = true;
        },
        hideTimesBox() {
            this.isTimesBoxVisible = false;
        },
        showRewardPopup() {
            this.rewardsPopupVisible = !this.rewardsPopupVisible;
            this.isPopupOverlayVisible = !this.isPopupOverlayVisible;
        },
        showRulesPopup() {
            this.rulesPopupVisible = !this.rulesPopupVisible;
            this.isPopupOverlayVisible = !this.isPopupOverlayVisible;
        },

        activateTab(tabId) {
            this.activeTab = tabId;
            if (tabId === 'thisweek' && !this.isLoadedThisWeek) {
                this.isLoadedThisWeek = true;
            }
            if (tabId === 'lastweek' && !this.isLoadedLastWeek) {
                this.isLoadedLastWeek = true;
            }
        },
        fetchWeeklyCurrentRewardData() {
            this.loading = true;
            axios.get('https://zeep.live/api/top-call-weekly').then(response => {
                this.weeklyCurrentRewardData = response.data.result.weeklyrewards;
            })
                .catch(error => {
                    console.error("Error Featching Data", error)
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    },
}    
</script>