<template>
   <div>
      <LoadingOverlay :loading="loading" />
      <div class="rewards-bg">
         <h3 class="txtb">Today</h3>
         <img :src='pinkBarBg' class="date-bg" alt="Pink Topbar Image" />
         <div class="afterbefore"></div>
         <img class="list-top-bg" :src='pinkBackgroundImage' alt="Pink Background Image" />
         <div class="daily-reward-box rewardPhoto">
            <div class="wrapDiv">
               <div class="coin-box">
                  <div class="coin-text">
                     <div class="text-white"
                        @click="redirectProfile(topCharmTodayData[1].id, topCharmTodayData[1].profile_id, topCharmTodayData[1].charm_level)">
                        <div class="img-img">
                           <div class="overlap">
                              <img :src='zhibojianFansSecond' alt="Frame Iamge">
                              <div class="coin-profPic" v-if="topCharmTodayData.length > 1">
                                 <img class="overlapImg" :src='topCharmTodayData[1].profile_images[0].image_name'
                                    alt="User Image" />
                              </div>
                           </div>
                           <img class="smallpoint" :src='rank2' alt="Rank 2" />
                           <div class="pointsBox" v-if="topCharmTodayData.length > 1">
                              <img class="points" :src='topCharmTodayData[1].current_charm_level_icon'
                                 alt="Label Image" />
                              <span class="value">{{ topCharmTodayData[1].charm_level }}</span>
                           </div>
                        </div>
                        <div class="mt-20" v-if="topCharmTodayData.length > 1">
                           <span class="name">
                              <div :class="{ 'marqueeContainer': topCharmTodayData[1].name.length > 10 }">
                                 <div v-if="topCharmTodayData[1].name.length > 10" class="marquee">
                                    <span class="marquee-content">{{ topCharmTodayData[1].name }}</span>
                                 </div>
                                 <div v-else>{{ topCharmTodayData[1].name }}</div>
                              </div>
                           </span>
                           <span class="spanBox beans-box">
                              <img :src='beansIcon' alt="Beans Icon" />
                              {{ topCharmTodayData[1].daily_earning_beans }}
                           </span>
                        </div>
                     </div>
                  </div>
                  <div class="coin-text active">
                     <div class="text-white"
                        @click="redirectProfile(topCharmTodayData[0].id, topCharmTodayData[0].profile_id, topCharmTodayData[0].charm_level)">
                        <div class="img-img">
                           <div class="overlap">
                              <img class="activeZoom" :src='zhibojianFansFirst' alt="Frame Image" />
                              <div class="coin-profPicActive" v-if="topCharmTodayData.length > 0">
                                 <img class="overlapImg overlapActive"
                                    :src='topCharmTodayData[0].profile_images[0].image_name' alt="User Image" />
                              </div>
                           </div>
                           <img class="smallpoint" :src='rank1' alt="Rank 1" />
                           <div class="pointsBox" v-if="topCharmTodayData.length > 0">
                              <img class="points" :src='topCharmTodayData[0].current_charm_level_icon'
                                 alt="Label Image">
                              <span class="value">{{ topCharmTodayData[0].charm_level }}</span>
                           </div>
                        </div>
                        <div v-if="topCharmTodayData.length > 0">
                           <span class="name" style="text-align: center;">
                              <div :class="{ 'marqueeContainer': topCharmTodayData[0].name.length > 10 }">
                                 <div v-if="topCharmTodayData[0].name.length > 10" class="marquee">
                                    <span class="marquee-content">{{ topCharmTodayData[0].name }}</span>
                                 </div>
                                 <div v-else>{{ topCharmTodayData[0].name }}</div>
                              </div>
                           </span>
                           <span class="spanBox beans-box" style="text-align: center;">
                              <img :src='beansIcon' alt="Beans Icon" />
                              {{ topCharmTodayData[0].daily_earning_beans }}
                           </span>
                        </div>
                     </div>
                  </div>
                  <div class="coin-text">
                     <div class="text-white"
                        @click="redirectProfile(topCharmTodayData[2].id, topCharmTodayData[2].profile_id, topCharmTodayData[2].charm_level)">
                        <div class="img-img">
                           <div class="overlap">
                              <img :src='zhibojianFansThird' alt="Frame Image">
                              <div class="coin-profPic" v-if="topCharmTodayData.length > 2">
                                 <img class="overlapImg" :src='topCharmTodayData[2].profile_images[0].image_name'
                                    alt="User Image" />
                              </div>
                           </div>
                           <img class="smallpoint" :src='rank3' alt="Rank 3">
                           <div class="pointsBox" v-if="topCharmTodayData.length > 2">
                              <img class="points" :src='topCharmTodayData[2].current_charm_level_icon'
                                 alt="Label Image">
                              <span class="value">{{ topCharmTodayData[2].charm_level }}</span>
                           </div>
                        </div>
                        <div class="mt-20" v-if="topCharmTodayData.length > 2">
                           <span class="name">
                              <div :class="{ 'marqueeContainer': topCharmTodayData[2].name.length > 10 }">
                                 <div v-if="topCharmTodayData[2].name.length > 10" class="marquee">
                                    <span class="marquee-content">{{ topCharmTodayData[2].name }}</span>
                                 </div>
                                 <div v-else>{{ topCharmTodayData[2].name }}</div>
                              </div>
                           </span>
                           <span class="spanBox beans-box">
                              <img :src='beansIcon' alt="Beans Icon" />
                              {{ topCharmTodayData[2].daily_earning_beans }}
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="podium">
                  <img class="podiumImg" :src='pinkPodiumBg' alt="Podium Image" />
               </div>
            </div>
         </div>
         <!-- User List Section -->
         <div class="listingPattern">
            <div class="fixHeight">
               <div class="listBoxes" v-for="(reward, index) in topCharmTodayData.slice(3)" :key="reward.id"
                  @click="redirectProfile(reward.id, reward.profile_id, reward.charm_level)">
                  <div class="leftBxb">
                     <div class="s-no">{{ index + 4 }}</div>
                     <div class="listIconBox">
                        <div class="profPic">
                           <!-- <img :src='reward.profile_images[0].image_name' class="user" alt="" /> -->
                           <img
                              :src='(reward.profile_images.length > 0) ? reward.profile_images[0].image_name : defaultUserImage'
                              class="user" alt="User Image" />
                        </div>
                        <img class="frames" :src='reward.current_pic_frame' alt="Profile Frame Image" />
                     </div>
                     <span>
                        <div :class="{ 'marqueeContainer': reward.name.length > 10 }">
                           <div v-if="reward.name.length > 10" class="marquee">
                              <span class="marquee-content">{{ reward.name }}</span>
                           </div>
                           <div v-else>{{ reward.name }}</div>
                        </div>
                        <div class="base">
                           <img :src='reward.current_charm_level_icon' alt="Charm Level Image" />
                           <b>{{ reward.charm_level }}</b>
                        </div>
                     </span>
                  </div>
                  <div class="rightBxb">
                     <span><img :src='beansIcon' alt="Beans Icon" />{{ reward.daily_earning_beans }}</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import axios from 'axios';
import pinkBarBg from '../../assets/images/pink-bar-bg.png'
import pinkBackgroundImage from '../../assets/images/pink-background-image.png';
import zhibojianFansSecond from '../../assets/images/zhibojian_fans_sec.png';
import zhibojianFansFirst from '../../assets/images/zhibojian_fans_first.png';
import zhibojianFansThird from '../../assets/images/zhibojian_fans_thi.png';
import rank1 from '../../assets/images/rank-1.png';
import rank2 from '../../assets/images/rank-2.png';
import rank3 from '../../assets/images/rank-3.png';
import beansIcon from '../../assets/images/beans-icon.png';
import pinkPodiumBg from '../../assets/images/pink-podium-bg.png';
import defaultUserImage from '../../assets/images/default_profile.jpeg';
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
   name: 'TopCharmDailyTodayTabCont',
   components: {
      LoadingOverlay
   },
   data() {
      return {
         topCharmTodayData: [],
         pinkBarBg: pinkBarBg,
         pinkBackgroundImage: pinkBackgroundImage,
         zhibojianFansSecond: zhibojianFansSecond,
         zhibojianFansFirst: zhibojianFansFirst,
         zhibojianFansThird: zhibojianFansThird,
         beansIcon: beansIcon,
         rank1: rank1,
         rank2: rank2,
         rank3: rank3,
         pinkPodiumBg: pinkPodiumBg,
         defaultUserImage: defaultUserImage,
         loading: false,

      }
   },
   mounted() {
      this.fetchTopCharmTodayData();
   },
   methods: {
      fetchTopCharmTodayData() {
         this.loading = true;
         axios.get('https://zeep.live/api/top-charm-weekly-daily?type=today').then(response => {
            this.topCharmTodayData = response.data.result.data;
            this.checkAllImagesExist();
         })
            .catch(error => {
               console.error("Enter Featching Today Data", error)
            })
            .finally(() => {
               this.loading = false;
            })
      },
      checkAllImagesExist() {
         this.topCharmTodayData.forEach(reward => {
            if (reward.profile_images.length > 0) {
               const imageUrl = reward.profile_images[0].image_name;
               this.checkImageExists(imageUrl)
                  .then(exists => {
                     if (!exists) {
                        reward.profile_images[0].image_name = this.defaultUserImage;
                     }
                  });
            }
         });
      },
      checkImageExists(imageUrl) {
         return new Promise((resolve, reject) => {
            var img = new Image();
            img.onload = function () {
               resolve(true);
            };
            img.onerror = function () {
               resolve(false);
            };
            img.src = imageUrl;
         });
      },
      // Get User ingo on click 
      redirectProfile(id, profile_id, level) {
         if (window.navigator.userAgent.indexOf("Android") > -1) {
            const baseURL = 'female-weekly-rewards';
            const params = {
               id: id,
               profile_id: profile_id,
               level: level
            };
            const queryString = Object.keys(params)
               .map(key => `${key}=${params[key]}`)
               .join('&');
            const url = `${baseURL}?${queryString}`;
            Android.redirectProfile(url);
         }
      }
   }
}
</script>