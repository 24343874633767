<template>
    <div>
        <LoadingOverlay :loading="loading" />
        <div class="daily-reward-box rewardPhoto">
            <div class="wrapDiv">
                <div class="coin-box">
                    <div class="coin-text">
                        <div class="img-img">
                            <div class="overlap" v-if="topPkThisWeekData.length > 1">
                                <img :src='topPkThisWeekData[1].current_pic_frame' alt="" />
                                <div class="profPic-box">
                                    <img class="overlapImg" :src='topPkThisWeekData[1].profile_images[0].image_name'
                                        alt="" />
                                </div>
                            </div>
                            <div class="pointsBox" v-if="topPkThisWeekData.length > 1">
                                <img class="points" :src='topPkThisWeekData[1].current_charm_level_icon' alt="" />
                                <span class="value">{{ topPkThisWeekData[1].charm_level }}</span>
                            </div>
                        </div>
                        <div class="userNameBox" v-if="topPkThisWeekData.length > 1">
                            <div :class="{ 'marqueeContainer': topPkThisWeekData[1].name.length > 10 }">
                                <div v-if="topPkThisWeekData[1].name.length > 10" class="marquee">
                                    <div class="marquee-content">{{ topPkThisWeekData[1].name }}</div>
                                </div>
                                <div v-else>{{ topPkThisWeekData[1].name }}</div>
                            </div>
                            <span class="spanBox beans-box">
                                <img :src='beansIcon' alt="" />{{ topPkThisWeekData[1].weekly_earning_beans }}
                            </span>
                        </div>
                    </div>
                    <div class="coin-text active">
                        <div class="img-img">
                            <div class="overlap" v-if="topPkThisWeekData.length > 0">
                                <img :src='topPkThisWeekData[0].current_pic_frame' alt="" />
                                <div class="profPic-box">
                                    <img class="overlapImg" :src='topPkThisWeekData[0].profile_images[0].image_name'
                                        alt="" />
                                </div>
                            </div>
                            <div class="pointsBox" v-if="topPkThisWeekData.length > 0">
                                <img class="points" :src='topPkThisWeekData[0].current_charm_level_icon' alt="" />
                                <span class="value">{{ topPkThisWeekData[0].charm_level }}</span>
                            </div>
                        </div>
                        <div class="userNameBox" v-if="topPkThisWeekData.length > 0">
                            <div :class="{ 'marqueeContainer': topPkThisWeekData[0].name.length > 10 }">
                                <div v-if="topPkThisWeekData[0].name.length > 10" class="marquee">
                                    <div class="marquee-content">{{ topPkThisWeekData[0].name }}</div>
                                </div>
                                <div v-else>{{ topPkThisWeekData[0].name }}</div>
                            </div>
                            <span class="spanBox beans-box">
                                <img :src='beansIcon' alt="" />{{ topPkThisWeekData[0].weekly_earning_beans }}
                            </span>
                        </div>
                    </div>
                    <div class="coin-text">
                        <div class="img-img">
                            <div class="overlap" v-if="topPkThisWeekData.length > 2">
                                <img :src='topPkThisWeekData[2].current_pic_frame' alt="" />
                                <div class="profPic-box">
                                    <img class="overlapImg" :src='topPkThisWeekData[2].profile_images[0].image_name'
                                        alt="" />
                                </div>
                            </div>
                            <div class="pointsBox" v-if="topPkThisWeekData.length > 2">
                                <img class="points" :src='topPkThisWeekData[2].current_charm_level_icon' alt="" />
                                <span class="value">{{ topPkThisWeekData[2].charm_level }}</span>
                            </div>
                        </div>
                        <div class="userNameBox" v-if="topPkThisWeekData.length > 2">
                            <div :class="{ 'marqueeContainer': topPkThisWeekData[2].name.length > 10 }">
                                <div v-if="topPkThisWeekData[2].name.length > 10" class="marquee">
                                    <div class="marquee-content">{{ topPkThisWeekData[2].name }}</div>
                                </div>
                                <div v-else>{{ topPkThisWeekData[2].name }}</div>
                            </div>
                            <span class="spanBox beans-box">
                                <img :src='beansIcon' alt="" />{{ topPkThisWeekData[2].weekly_earning_beans }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="podium">
                    <img class="podiumImg" :src='pkPodiumImage' alt="" />
                </div>
            </div>
        </div>
        <div class="listingPattern">
            <div class="fixHeight">
                <div class="listBoxes" v-for="(reward, index) in topPkThisWeekData.slice(3)" :key="reward.id">
                    <div class="leftBxb">
                        <div class="s-no">{{ index + 4 }}</div>
                        <div class="listIconBox">
                            <div class="profPic">
                                <img :src='(reward.profile_images.length > 0) ? reward.profile_images[0].image_name : defaultUserImage'
                                    class="user" alt="User Image" />
                            </div>
                            <img class="frames" :src='reward.current_pic_frame' alt="" />
                        </div>
                        <div>
                            <div :class="{ 'marqueeContainer': reward.name.length > 10 }">
                                <div v-if="reward.name.length > 10" class="marquee">
                                    <div class="marquee-content">{{ reward.name }}</div>
                                </div>
                                <div v-else>{{ reward.name }}</div>
                            </div>
                            <div class="base">
                                <img :src='reward.current_charm_level_icon' alt="" />
                                <b>{{ reward.charm_level }}</b>
                            </div>
                        </div>
                    </div>
                    <div class="rightBxb">
                        <span><img :src='beansIcon' alt="Beans Icon" />{{ reward.weekly_earning_beans
                            }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import pkPodiumImage from '../../assets/images/pk-podium-image.svg'
import pkTopBorderLineBg from '../../assets/images/pk-top-border-line-bg.png'
import beansIcon from '../../assets/images/beans-icon.png'
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
    name: 'TopPkThisWeekTabCont',
    components: {
        LoadingOverlay
    },
    data() {
        return {
            topPkThisWeekData: [],
            pkPodiumImage,
            pkTopBorderLineBg,
            beansIcon,
            loading: false,
        }
    },
    mounted() {
        this.fetchThisWeekData()
    },
    methods: {
        fetchThisWeekData() {
            this.loading = true;
            axios.get('https://zeep.live/api/top-charm-weekly-daily?type=this_week').then(response => {
                this.topPkThisWeekData = response.data.result.data;
                this.checkAllImagesExist();
            })
                .catch(error => {
                    console.error("Error Featching This Week Data", error)
                })
                .finally(() =>{
                    this.loading = false;
                }) 
        },
        checkAllImagesExist() {
            this.topPkThisWeekData.forEach(reward => {
                if (reward.profile_images.length > 0) {
                    const imageUrl = reward.profile_images[0].image_name;
                    this.checkImageExists(imageUrl)
                        .then(exists => {
                            if (!exists) {
                                reward.profile_images[0].image_name = this.defaultUserImage;
                            }
                        });
                }
            });
        },
        checkImageExists(imageUrl) {
            return new Promise((resolve, reject) => {
                var img = new Image();
                img.onload = function () {
                    resolve(true);
                };
                img.onerror = function () {
                    resolve(false);
                };
                img.src = imageUrl;
            });
        },
    }
}    
</script>