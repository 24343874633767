<template>
    <div>
        <LoadingOverlay :loading="loading" />
        <div class="daily-reward-box rewardPhoto">
            <div class="wrapDiv">
                <div class="coin-box lastWeek-coinBox">
                    <div class="coin-text">
                        <div class="img-img">
                            <div class="overlap" v-if="topPkLastWeekData.length > 1">
                                <img :src='topPkLastWeekData[1].user.last_pic_frame' alt="" />
                                <div class="profPic-box">
                                    <img class="overlapImg" :src='topPkLastWeekData[1].user.profile_images[0].image_name'
                                        alt="" />
                                </div>
                            </div>
                            <div class="pointsBox" v-if="topPkLastWeekData.length > 1">
                                <img class="points" :src='topPkLastWeekData[1].user.last_charm_level_icon' alt="" />
                                <span class="value">{{ topPkLastWeekData[1].user.charm_level }}</span>
                            </div>
                        </div>
                        <div class="userNameBox" v-if="topPkLastWeekData.length > 1">
                            <div :class="{ 'marqueeContainer': topPkLastWeekData[1].user.name.length > 10 }">
                                <div v-if="topPkLastWeekData[1].user.name.length > 10" class="marquee">
                                    <div class="marquee-content">{{ topPkLastWeekData[1].user.name }}</div>
                                </div>
                                <div v-else>{{ topPkLastWeekData[1].user.name }}</div>
                            </div>
                            <span class="spanBox beans-box">
                                <img :src='beansIcon' alt="" />{{ topPkLastWeekData[1].total_coin_earned }}
                            </span>
                            <span class="rewards">Reward
                                <span class="rewardsCoinVal">{{ topPkLastWeekData[1].reward_coin }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="coin-text active">
                        <div class="img-img">
                            <div class="overlap" v-if="topPkLastWeekData.length > 0">
                                <img :src='topPkLastWeekData[0].user.last_pic_frame' alt="" />
                                <div class="profPic-box">
                                    <img class="overlapImg" :src='topPkLastWeekData[0].user.profile_images[0].image_name'
                                        alt="" />
                                </div>
                            </div>
                            <div class="pointsBox" v-if="topPkLastWeekData.length > 2">
                                <img class="points" :src='topPkLastWeekData[0].user.last_charm_level_icon' alt="" />
                                <span class="value">{{ topPkLastWeekData[0].user.charm_level }}</span>
                            </div>
                        </div>
                        <div class="userNameBox" v-if="topPkLastWeekData.length > 0">
                            <div :class="{ 'marqueeContainer': topPkLastWeekData[0].user.name.length > 10 }">
                                <div v-if="topPkLastWeekData[0].user.name.length > 10" class="marquee">
                                    <div class="marquee-content">{{ topPkLastWeekData[0].user.name }}</div>
                                </div>
                                <div v-else>{{ topPkLastWeekData[0].user.name }}</div>
                            </div>
                            <span class="spanBox beans-box">
                                <img :src='beansIcon' alt="" />{{ topPkLastWeekData[0].total_coin_earned }}
                            </span>
                            <span class="rewards">Reward
                                <span class="rewardsCoinVal">{{ topPkLastWeekData[0].reward_coin }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="coin-text">
                        <div class="img-img">
                            <div class="overlap" v-if="topPkLastWeekData.length > 2">
                                <img :src='topPkLastWeekData[2].user.last_pic_frame' alt="" />
                                <div class="profPic-box">
                                    <img class="overlapImg" :src='topPkLastWeekData[2].user.profile_images[0].image_name'
                                        alt="" />
                                </div>
                            </div>
                            <div class="pointsBox" v-if="topPkLastWeekData.length > 2">
                                <img class="points" :src='topPkLastWeekData[2].user.last_charm_level_icon' alt="" />
                                <span class="value">{{ topPkLastWeekData[2].user.charm_level }}</span>
                            </div>
                        </div>
                        <div class="userNameBox" v-if="topPkLastWeekData.length > 2">
                            <div :class="{ 'marqueeContainer': topPkLastWeekData[2].user.name.length > 10 }">
                                <div v-if="topPkLastWeekData[2].user.name.length > 10" class="marquee">
                                    <div class="marquee-content">{{ topPkLastWeekData[2].user.name }}</div>
                                </div>
                                <div v-else>{{ topPkLastWeekData[2].user.name }}</div>
                            </div>
                            <span class="spanBox beans-box">
                                <img :src='beansIcon' alt="" />{{ topPkLastWeekData[2].total_coin_earned }}
                            </span>
                            <span class="rewards">Reward
                                <span class="rewardsCoinVal">{{ topPkLastWeekData[2].reward_coin }}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="podium">
                    <img class="podiumImg" :src='pkPodiumImage' alt="" />
                </div>
            </div>
        </div>
        <div class="listingPattern">
            <div class="fixHeight">
                <div class="listBoxes" v-for="(reward, index) in topPkLastWeekData.slice(3)" :key="reward.id">
                    <div class="leftBxb">
                        <div class="s-no">{{ index + 4 }}</div>
                        <div class="listIconBox">
                            <div class="profPic">
                                <img :src='(reward.user.profile_images.length > 0) ? reward.user.profile_images[0].image_name : defaultUserImage'
                                    class="user" alt="User Image" />
                            </div>
                            <img class="frames" :src='reward.user.last_pic_frame' alt="" />
                        </div>
                        <div>
                            <div :class="{ 'marqueeContainer': reward.user.name.length > 10 }">
                                <div v-if="reward.user.name.length > 10" class="marquee">
                                    <div class="marquee-content">{{ reward.user.name }}</div>
                                </div>
                                <div v-else>{{ reward.user.name }}</div>
                            </div>
                            <div class="base">
                                <img :src='reward.user.last_charm_level_icon' alt="" />
                                <b>{{ reward.user.charm_level }}</b>
                            </div>
                        </div>
                    </div>
                    <div class="rightBxb">
                        <span>
                            <img :src='beansIcon' alt="Beans Icon" />{{ reward.total_coin_earned }}
                            <br />
                            <span class="rewards" v-if="index < 7">Reward {{ reward.reward_coin }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import pkPodiumImage from '../../assets/images/pk-podium-image.svg'
import beansIcon from '../../assets/images/beans-icon.png'
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
    name: 'TopPkLastWeekTabCont',
    components: {
        LoadingOverlay
    },
    data() {
        return {
            topPkLastWeekData: [],           
            pkPodiumImage,            
            beansIcon,
            loading: false,
        }
    },
    mounted() {
        this.fetchThisWeekData()
    },
    methods: {
        fetchThisWeekData() {
            this.loading = true;
            axios.get('https://zeep.live/api/top-charm-weekly-daily?type=last_week').then(response => {
                this.topPkLastWeekData = response.data.result.data;
                this.checkAllImagesExist();
            })
                .catch(error => {
                    console.error("Error Featching This Week Data", error)
                })
                .finally(() =>{
                    this.loading = false;
                })
        },
        checkAllImagesExist() {
            this.topPkLastWeekData.forEach(reward => {
                if (reward.profile_images.length > 0) {
                    const imageUrl = reward.profile_images[0].image_name;
                    this.checkImageExists(imageUrl)
                        .then(exists => {
                            if (!exists) {
                                reward.profile_images[0].image_name = this.defaultUserImage;
                            }
                        });
                }
            });
        },
        checkImageExists(imageUrl) {
            return new Promise((resolve, reject) => {
                var img = new Image();
                img.onload = function () {
                    resolve(true);
                };
                img.onerror = function () {
                    resolve(false);
                };
                img.src = imageUrl;
            });
        },
    }
}    
</script>